import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './Jan22Footer';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavJan22 from './NavJan22';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import Modification from '../../images/Modification.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import Elementary from '../../images/Elementary.png'
import Firefly from '../../images/Firefly.png'
import Slide from '../../images/Slide.png'
import Claude from '../../images/Claude.png'
const Jan22 = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavJan22 module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">AI for Directors of Special Education</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="william"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🗺️🚀</span> Session Roadmap
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>

                        </div>
                        <section id="william" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🗺️🚀 Roadmap</h2>
                            <div className="MoASSP-activities-wrapper">
                                {/* Navigation bar */}
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="1" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">1</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="2" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">2</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="3" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">3</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="4" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">4</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="5" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">5</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="6" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">6</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="7" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">7</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="8" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">8</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="9" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">9</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="10" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">10</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="11" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">11</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="12" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">12</ScrollLink>
                                </div>

                                {/* Activity 1 */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 1: </span>
                                        Introduction to ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                            <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> First, send this prompt to ChatGPT: "As a Director of Special Education, create a survey to gather feedback from special education coordinators and staff in my district on the challenges they have encountered at the beginning of the school year."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Next, send this prompt to ChatGPT: "Now, generate a template for weekly email updates to special education coordinators and staff. At the beginning, include a section to add a link for the survey you just created."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 2: </span>
                                        Learn How to Design Effective ChatGPT Prompts with the Role, Action, Context, Format Framework
                                    </h3>

                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={PromptDesign} alt="Prompt Framework" />
                                                    <span className="MoASSP-link-label">Prompt Framework</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Here is an example of the Role, Action, Context, Format Framework: "Act as a Director of Special Education. (Role) Draft a parent-friendly guide for understanding their rights and responsibilities under the Individuals with Disabilities Education Act (IDEA). (Action) Include explanations of key terms, the process for requesting evaluations, and a summary of procedural safeguards. (Context) Ensure the guide is accessible, easy to read, and provides space for parents to jot down notes during meetings. (Format)." Essentially, you want to give it a role, what it should do as that role, what context someone would need to produce an applicable result, and the format you would like to see it in. Using this framework, design a prompt related to your role as Director of Special Education.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 3: </span>
                                        Using ChatGPT to Summarize a Document
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/12y5ISvMMfvchn_zQj75-B6plGFY5GoLfy-XDYfxZ1wk/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Doc} alt="Doc" />
                                                    <span className="MoASSP-link-label">Policy Document</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the document found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Word (.docx)".
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Open ChatGPT and click the paperclip icon in the prompt bar. Select the document you just downloaded from your Downloads folder. Then, send the following prompt to ChatGPT along with the document: "Summarize this school policy into a clear, concise overview highlighting the key points and action items. Tailor the summary for a school leader to use in decision-making."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 4: </span>
                                        Exploring the Canvas Feature in ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Send the following prompt to ChatGPT: "Write a professional letter to the special education community about a referendum to add sensory and therapy spaces. Thank the community, explain how this supports students' needs and improves outcomes, and invite them to a meeting for input. Format it professionally with a header, greeting, body, and closing, and send it to the canvas for editing."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Once ChatGPT generates the letter, it will automatically appear on the right side of your screen in the canvas. Open the canvas to view and refine the letter as needed. Adjust the tone or details as needed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="5" id="5" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 5: </span>
                                        Fixing ChatGPT's Writing Tone
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1M49fFU0jS-9VZAu7HWm0zowH8a_THBsEskmMnRBrlZk/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Doc} alt="Doc" />
                                                    <span className="MoASSP-link-label">Document</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the document in the "Links Needed for Activity" section. Follow along with the instructor to fix ChatGPT's Writing Tone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="6" id="6" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 6: </span>
                                        Using AI in Communication - Navigating Difficult Emails with ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Send this prompt to ChatGPT: "Act as a [Position e.g., Director of Special Education]. You've received a difficult email from a staff member about [Issue]. Draft a professional, empathetic response that acknowledges their concerns, clarifies misunderstandings, and outlines steps being taken to address the issue. If appropriate, suggest a follow-up meeting to discuss further. Include the following points: [what you would like to include]."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Explore other ways to use ChatGPT for communication. For example:
                                                <br />
                                                <li> - Draft newsletters for the special education community, summarizing recent updates or achievements.</li>
                                                <li> - Create engaging social media posts to highlight upcoming events, success stories, or resource updates.</li>
                                                <li> - Generate professional letters for various audiences, such as parents, staff, or external stakeholders.</li>
                                                <li> - Design templates for weekly or monthly updates to keep everyone informed.</li>
                                                <li> - Develop communication strategies for presenting sensitive topics, such as policy changes or new initiatives.</li>
                                                <li> - Brainstorm ideas for creative ways to share feedback or encourage participation in district activities.</li>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="7" id="7" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 7: </span>
                                        Human Capital Leadership: Performance Documentation Strategies
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Copy and paste the following prompt into ChatGPT to develop a coaching framework for a special education teacher: "As a Director of Special Education, develop coaching notes for a special education teacher who demonstrates engaging activities but lacks systematic documentation of student progress. Focus on guiding the teacher to align instructional strategies more closely with individualized education program (IEP) goals, improve progress monitoring, and create clearer evidence of student learning outcomes."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Copy and paste the following prompt into ChatGPT to create a professional improvement plan: "Craft a professional improvement plan for a special education program coordinator who is struggling with implementing district policies, maintaining accurate documentation, and ensuring effective communication. The plan should identify performance gaps, set measurable professional development goals, and provide targeted support to improve overall special education service delivery."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="8" id="8" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 8: </span>
                                        Navigating the RFP Process for Contracted Services with ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Copy and paste the following prompt into ChatGPT to write a Request for Bid Advertisement: "Act as if you are the Director of Special Education in a school district and seeking to contract occupational therapy services for students with individualized education plans (IEPs). Write a Request for Bid Advertisement with as much detail as you can, I will fill out the rest."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Copy and paste the following prompt into ChatGPT to create an objective rubric for scoring the bid proposals: "Can you please create an objective rubric we can use to score the bid proposals for contracted occupational therapy services? Include criteria such as qualifications, experience, cost, and alignment with student needs."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> Copy and paste the following prompt into ChatGPT to write letters to the RFP respondents: "Please write letters to the RFP respondents for contracted occupational therapy services. Write one letter for the award recipient and another letter for those we are not selecting."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> Copy and paste the following prompt into ChatGPT to write a memo to the school board: "Write a memo to my school board that outlines the bid process for contracted occupational therapy services and recommends that they award the bid to the recipient I selected. Explain the objectivity in this process and detail the steps."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>5.</strong> Copy and paste the following prompt into ChatGPT to draft a contract for the services: "My district is NDCEL Public Schools, and we awarded the contract for occupational therapy services to XYZ Therapy Group. Please draft a contract for utilizing their services."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="9" id="9" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 9: </span>
                                        Anonymizing Data for ChatGPT Analysis and Re-Mapping Student IDs
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/spreadsheets/d/1Z_8OUAW4rNQtlDxHbpCe64xIK7qdi-or3eSZfyKL2S4/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={GoogleSheetIcon} alt="Mock Data" />
                                                    <span className="MoASSP-link-label">Mock Data</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Anonymize} alt="Anonymize Data" />
                                                    <span className="MoASSP-link-label">Anonymize Data</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Follow along with the instructor to anonymize student data using the Mock Data and "Anonymize Data" resource provided in the links section.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Use ChatGPT to analyze the anonymized data. Upload the anonymized Excel file by clicking the paper clip icon (📎) in ChatGPT.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> Copy and paste the following prompt into ChatGPT: "Analyze the student data in the attached Excel file. Identify significant trends over time, highlight areas of concern, and suggest actionable interventions. Use the Anonymized IDs to reference students. Provide visualizations to support your analysis."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> Map the results back to the original students using the secure mapping file created during the anonymization process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="10" id="10" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 10: </span>
                                        How to Modify Assignments with ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1XqypS1cXLf2RuOxnzfMfJavO7-3zj_KJCqR4_FIefnI/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Modification} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">A Teachers Guide: Assignment Modification with ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the guide in the "Links Needed for Activity" section. Let's read through it and apply it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="11" id="11" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 11: </span>
                                        Using MagicSchool for Assignment Modification
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">MagicSchool</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/18ylptbWgxiEUkgVMOtMW5Xa0oGMD6L7jzXKsPTt0G7I/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic1} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Simple Guide: How to Use MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Click the "MagicSchool" link in the "Links Needed for Activity" section. Once there, go to the Student Support Tools. Review the tools available for modifying assignments for students.
                                            </p>
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> The most relevant tools are Text Leveler, Assignment Scaffolder, and Text Scaffolder.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="12" id="12" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 11: </span>
                                        Explore Additional AI Tools for Educators
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Guide2} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">All Popular AI Platforms for Teachers</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://app.diffit.me/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Diffit} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Diffit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Start by using ChatGPT and MagicSchool. Trying to use all tools at once can feel overwhelming. To explore additional popular platforms for teachers, click "All Popular AI Platforms for Teachers" in the "Links Needed for Activity" section.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Teachers often find "Diffit" to be especially useful. Explore it by clicking the Diffit link in the 'Links Needed for Activity' section.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default Jan22;
