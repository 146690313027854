import emailjs from "emailjs-com";
import React from "react";

const AASAFooter = () => {
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm(
      "service_1g4hajp",
      "template_fjby2xf",
      e.target,
      "user_HAMTDSUiL200UN9RQyPHI"
    );
    document.getElementById("success").style.display = "flex";
    document.getElementById("MoASSP-contact-form").style.display = "none";
  }
  return (
    <footer id="contact" className="MoASSP-footer AASA-footer">
      <div className="MoASSP-footer-container">
        <div className="MoASSP-footer-section">
          <h4 className="MoASSP-footer-heading">Conference Resources</h4>
          <p className="MoASSP-footer-text">
            Access all the essential resources from the conference in one place. From AI tools and templates to policy guides and implementation frameworks, find everything you need to continue your AI journey.
          </p>
        </div>
        <div className="MoASSP-footer-section">
          <h4 className="MoASSP-footer-heading">Website Issues? Contact Gruvy Education:</h4>
          <a
            className="MoASSP-footer-link"
            href="mailto:william.grube@gruvyeducation.com"
          >
            Email: william.grube@gruvyeducation.com
          </a>
          <a className="MoASSP-footer-link" href="tel:507-476-8096">
            Phone: 507-476-8096
          </a>
        </div>
      </div>
      <div className="MoASSP-footer-bottom">
        <p className="MoASSP-footer-details">
          Hosted by: AASA, The School Superintendents Association<br />
          Partners: Age of Learning, Google, Magma Math, MagicSchoolAI, ThoughtExchange AI<br />
        </p>
        <p className="MoASSP-footer-copyright">
          &copy; {new Date().getFullYear()} Gruvy Education | All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default AASAFooter;