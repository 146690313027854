import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './AASAFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavMoASSPOct28 from './NavAASA';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import Streamline from '../../images/Streamline.png'
import NumberCrunch from '../../images/NumberCrunch.png'
import ThirdBrain from '../../images/ThirdBrain.png'
import Slide from '../../images/Slide.png'
import AzizLogo from '../../images/AzizLogo.png'
import ESDevelopment from '../../images/ESDevelopment.png'
import Peninsula from '../../images/Peninsula.png'
import RupakLogo from '../../images/RupakLogo.png'
import Richland from '../../images/Richland.png'
import SAUSD from '../../images/SAUSD.png'
import SH_orange from '../../images/SH_orange.png'
import BSD from '../../images/BSD.jpg'
import TFALogo from "../../images/TFALogo.png";
import GruvyLogo from "../../images/logo-no-background.png";
import InteractiveWorkbook from '../../images/InteractiveWorkbook.png'
import CustomGPT from '../../images/CustomGPT.png'
import Path from '../../images/Path.png'
import Slides from '../../images/Slide.png'
import Activity1 from '../../images/Activity1.png'
import Sheets from '../../images/GoogleSheetIcon.png'
import Activity2 from '../../images/Activity2.png'
import Sandbox from '../../images/Sandbox.png'
import Modification from '../../images/Modification.png'
import Elementary from '../../images/Elementary.png'

const AASAAllResources = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top AASA-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavMoASSPOct28 module="biz" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header AASA-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Complete Conference Resources and Many Extras!</div>
                        </div>
                    </header>
                    <div className="course-container">

                        <section name='ai-strategies' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title AASA-course-sub-title aiSupperSummitColor">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                {/* Resource 1 */}
                                <a
                                    href="https://docs.google.com/document/d/1W1z78ETVQx720WIV_PhXPWx9-t9LMRzXBgO9fbVMh4s/copy"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={InteractiveWorkbook}
                                            alt="Beassie AASA Turnover Retention Tools"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AASA AI Summit 1.0 Hackathon Interactive Workbook
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        This link creates a copy of an interactive workbook for the hackathon. Click
                                        the link and then click "Make a copy" to get started. We will reference it
                                        during the Hackathon.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/presentation/d/1chFkzyFTTbDGAVeWw0y4hiuEP-ZBAQvs/edit?usp=sharing&ouid=116264913639920976203&rtpof=true&sd=true"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slide}
                                            alt="From Policy to Practice: Building a District-Wide AI Framework That Works"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        From Policy to Practice: Building a District-Wide AI Framework That Works
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Slides with embedded links from our presentation.
                                    </p>
                                </a>

                                <a
                                    href="https://chatgpt.com/g/g-678a9a61b63c8191b5dcbc469a1f37d6-k-12-ai-guidance-assistant"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={CustomGPT} alt="AI Guidance Custom GPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Guidance Custom GPT
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        AI Assistant with curated content from CoSN, ISTE, UNESCO, US DoE,
                                        Digital Promise, and TeachAI for district AI guidance.
                                    </p>
                                </a>

                                <a
                                    href="https://psd401.ai"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Peninsula}
                                            alt="Peninsula School District AI Resources Website"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Peninsula School District AI Resources Website
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A website dedicated to our AI work. Find guidance, blog posts, tools,
                                        presentations, and curated research for AI in education.
                                    </p>
                                </a>

                                <a
                                    href="https://bsd48j-my.sharepoint.com/:p:/r/personal/langfordst_beaverton_k12_or_us/_layouts/15/doc2.aspx?sourcedoc=%7B02A16DFB-83C9-464C-ABBB-55039150C56B%7D&file=Leading%20AI%20to%20scale.pptx&action=edit&mobileredirect=true&ct=1737499026221&wdOrigin=OFFICECOM-WEB.START.UPLOAD&cid=582cf77c-5105-499b-a92c-df144a8a4414&wdPreviousSessionSrc=HarmonyWeb&wdPreviousSession=4b720235-8f50-4243-ab0f-21d6ae38c7b6"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Path}
                                            alt="Leading AI to Scale: Governance, Privacy, and Change Management"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Leading AI to Scale: Governance, Privacy, and Change Management
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Use this resource to scale AI in your district.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1YtAu6-2c9QpQCh2GMhnlnmnOBUpGhELY/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slide}
                                            alt="AI Strategies for School Business: Mastering Prompt Engineering for Maximum Efficiency"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Strategies for School Business: Mastering Prompt Engineering for Maximum Efficiency
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Slide deck on AI strategies and prompt engineering for schools.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/presentation/d/186pN9CmTypRDfE2UpI1WSbHLV0a_F49d/edit?usp=sharing&ouid=111837834560377585162&rtpof=true&sd=true"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slide}
                                            alt="Slides: AI Budgeting – Crunching Numbers at the Speed of Thought"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Budgeting – Crunching Numbers at the Speed of Thought
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Slide deck on AI budgeting.
                                    </p>
                                </a>

                                <a
                                    href="https://www.esdevelopmentgroup.com/thirdbrain"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={ThirdBrain}
                                            alt="The Emergence of a 'Third Brain': The Effects of AI on Management of Education & Its Application in Everyday Life"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        The Emergence of a "Third Brain": The Effects of AI on Management of Education & Its Application in Everyday Life
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A white paper on AI's impact on education and daily life.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1XwPpT4t6KCZuW-hKWoyjXjnpieRpsK-k/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Communications}
                                            alt="AI in Communications, Community Engagement, and Crisis Management Toolkit"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI in Communications, Community Engagement, and Crisis Management Toolkit
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Toolkit on using AI for communications, engagement, and crisis management.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/presentation/d/1wy2Wx8B5faiOTTCXxhQvei-5VZVZVAOx/edit?usp=sharing&ouid=105012365432858130503&rtpof=true&sd=true"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slides}
                                            alt="Presentation: Understanding Stakeholder Sentiment"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Presentation: Understanding Stakeholder Sentiment
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Slides on sentiment analysis in K12 education.
                                    </p>
                                </a>
                                
                                <a
                                    href="AI-Enhanced Crisis Response.pptx"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slides}
                                            alt="Presentation: AI-Enhanced Crisis Response"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Presentation: AI-Enhanced Crisis Response
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Slides from the AI-Enhanced Crisis Response session.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/15xGbFIpv-o0YOZu12WubRjxJboJlSrCC/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slide}
                                            alt="Beassie AASA Turnover Retention Tools"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Beassie AASA Turnover Retention Tools
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        PowerPoint for the HR track on predicting turnover and planning retention.
                                    </p>
                                </a>

                                <a
                                    href="https://www.canva.com/design/DAGcfs9ItCc/Mckr1a1KHHT9iQZPHYtdHg/view?utm_content=DAGcfs9ItCc&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h661ddf7e6b"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Sandbox}
                                            alt="The AI Sandbox: Playing your way into the future of learning with AI"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        The AI Sandbox: Playing your way into the future of learning with AI
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        The complete slide deck for the hackathon/workshop.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1pQZdVMrMs7CD88sqNlNQk7mbRf133heuSGz9lakBE4Y/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Guide1}
                                            alt="Using AI for Classroom Content Creation"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Using AI for Classroom Content Creation
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        How to use ChatGPT to create classroom content.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1XqypS1cXLf2RuOxnzfMfJavO7-3zj_KJCqR4_FIefnI/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Modification}
                                            alt="A Teachers Guide: Assignment Modification with ChatGPT"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        A Teachers Guide: Assignment Modification with ChatGPT
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        How to use ChatGPT to modify assignments.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1M2Dejn-OC27POEfaHSPsgVqSfc_eeCb6/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={AIresistant}
                                            alt="Creating AI-Resistant Curriculum: A Guide for Educators"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Creating AI-Resistant Curriculum: A Guide for Educators
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A guide to keeping students learning in the AI era.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1ttR5recd5AAm88gwcCMO5bGLnzkcNiTXF1noduXuc6w/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Practical}
                                            alt="Gruvy Education's Guide to Data Privacy With AI"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        A Practical Guide to Using ChatGPT as a School Leader
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Introduce ChatGPT as a tool to your leadership team.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1sMVqVShJOi539RIix7d68dW2J9cQJY6V/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        ChatGPT Guide for School Leaders: ChatGPT Uses & Prompts
                                    </h3>
                                    <p className="MoASSP-tool-text">30+ ways to use ChatGPT as a school leader.</p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1joLuo9fNFkJdamJVzWtZomVfGsp2tIogS2-WjFROvDs/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Policy}
                                            alt="Gruvy Education's Guide to Data Privacy With AI"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        OpenAI (ChatGPT) Privacy Policy Breakdown for School Leaders
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Overview of the privacy policy for ChatGPT's developer.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={DataGuide}
                                            alt="Gruvy Education's Guide to Student Data Privacy With AI"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Gruvy Education's Guide to Student Data Privacy With AI
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A school's guide to student data privacy with AI.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Anonymize}
                                            alt="Gruvy Education's Guide to Data Privacy With AI"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        How to anonymize student data for ChatGPT.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1-Wh0kmFzaDuGHNx--YOpTmugAx7yQ3VaQuk6NwAWHpk/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataAnalysis} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Using ChatGPT for Data Analysis: Prompt Ideas for School Leaders
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Ideas and prompts for data analysis with ChatGPT.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1DFaJDM6nI1LeJPEtQZFQIBTPbXOhG3E6/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={OverWelm} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">Introduction and Fundamentals of ChatGPT</h3>
                                    <p className="MoASSP-tool-text">
                                        Use this to introduce ChatGPT as a tool to staff members who feel unsure.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={PromptDesign} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">Framework for Designing ChatGPT Prompts</h3>
                                    <p className="MoASSP-tool-text">
                                        A guide on how school leaders can design ChatGPT prompts.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/18ylptbWgxiEUkgVMOtMW5Xa0oGMD6L7jzXKsPTt0G7I/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Magic1} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">Simple Guide: How to Use MagicSchool</h3>
                                    <p className="MoASSP-tool-text">
                                        Teach staff how to use MagicSchool.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1pzzLJeGkwmN1JQTRVhGXiVoareH6EufTozjtZ6O5I98/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Magic2} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Simple Guide: How to Use MagicSchool's MagicStudent
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Teach staff how to use MagicSchool's MagicStudent.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Guide for Navigating AI Tools as an Educator
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A list of popular AI tools for teachers.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1jB_5rMS7OyA3-X6DQdbXPxOoT4ue9Rx_iiw4Zx7VVoY/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Doc} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Streamlining Task Delegation With ChatGPT
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Transform how you delegate tasks to focus on higher-leverage work.
                                    </p>
                                </a>

                                <a
                                    href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Newsletter} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">AI School Leadership Minute</h3>
                                    <p className="MoASSP-tool-text">
                                        A concise AI newsletter for school leaders with no fluff.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1JbVxGnuFe4FjpDiiJDblvfgtxDa_olfsPapDaXnhHBA/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Slide} alt="Slide Icon" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        A Teachers Guide: How to Use AI to Create Slide Decks
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        How to use ChatGPT and Gamma to create ready-made slide decks.
                                    </p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1hBqQmZhdoMb_P77VMz_jkrPd1cexcd8Hm0C7rU1qsg8/edit?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Elementary} alt="Early Elementary Educator Guide" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        A Teachers Guide: Using AI as an Early Elementary Educator
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        How to use ChatGPT with early elementary students.
                                    </p>
                                </a>

                                <a
                                    href="https://www.teachai.org/"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeachAIpolicy} alt="TeachAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">TeachAI | AI Guidance for Schools</h3>
                                    <p className="MoASSP-tool-text">
                                        Free AI policy resources and guidance for schools.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={StudentGuide}
                                            alt="AI in Education: Teacher's Checklist for Success"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI in Education: Teacher's Checklist for Success
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        A practical checklist for AI implementation in the classroom.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={TeacherChecklist}
                                            alt="AI Classroom Use: Guidelines for Students"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Classroom Use: Guidelines for Students
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        An easy-to-understand graphic on responsible AI use.
                                    </p>
                                </a>

                                <a
                                    href="https://www.aiforeducation.io/ai-resources-main"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIEDU} alt="AIEDU" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">AI for Education | Downloadable Resources</h3>
                                    <p className="MoASSP-tool-text">Free AI resources for schools.</p>
                                </a>

                            </div>
                        </section>
                        <section id="aiSuperSUmmitPartners">
                            <div className="aiSuperSUmmitWrapper">
                                <h1 className="heading">
                                    Resources provided by:
                                </h1>
                                <div className="aisupersummitcards">
                                    {[
                                        AzizLogo,
                                        ESDevelopment,
                                        Peninsula,
                                        RupakLogo,
                                        Richland,
                                        SAUSD,
                                        SH_orange,
                                        BSD,
                                        TFALogo,
                                        GruvyLogo,
                                    ].map((item, index) => (
                                        <img key={index} src={item} alt="AI Super Summit Partners" />
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default AASAAllResources;
