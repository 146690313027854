import emailjs from "emailjs-com"
import React from 'react';

const BerthaFooter = () => {
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("MoASSP-contact-form").style.display = "none";
    }
    return (
        <footer id="contact" className="MoASSP-footer ">
            <div className="container footer-content text-center ">
                <div id='success' className="success MoASSP-footer-success">Sent!<br /><p className='small'>Thank you for reaching out.</p>
                <br/>
                    <a target="_blank" href='https://usemotion.com/meet/william-grube/meeting?d=30' className="btn submit-btn greenBtn">Schedule a Virtual Meeting</a>
                </div>
                <div id='MoASSP-contact-form' className="footer-bottom">
                    <div className="section-title">
                        <h2 className="textWhite">Get In Touch</h2>
                    </div>
                    <form className="newsletter-form" onSubmit={sendEmail}>
                        <input required type="text" name='name' placeholder='Your Name' className="form-control black"></input>
                        <input type="email" name='email' required placeholder='Email Adress' className="form-control black"></input>
                        <input type="text" name='phone' required placeholder='Phone number' className="form-control black"></input>
                        <textarea required name='message' rows="6" placeholder="Message.." className='form-control textArea black'></textarea>
                        <button type="submit" className="btn submit-btn">Send</button>
                        <a target="_blank" href='https://usemotion.com/meet/william-grube/meeting?d=30' className="btn submit-btn">Schedule a Virtual Meeting</a>
                    </form>
                </div>
            </div>
            <div className="MoASSP-footer-container">
                <div className="MoASSP-footer-section">
                    <h4 className="MoASSP-footer-heading">About Gruvy Education</h4>
                    <p className="MoASSP-footer-text">
                        We deliver AI training to schools and major educational associations nationwide.
                    </p>
                </div>
                <div className="MoASSP-footer-section">
                    <h4 className="MoASSP-footer-heading">Contact</h4>
                    <a className="MoASSP-footer-link" href="mailto:william.grube@gruvyeducation.com">
                        Email: william.grube@gruvyeducation.com
                    </a>
                    <a className="MoASSP-footer-link" href="tel:507-476-8096">
                        Phone: 507-476-8096
                    </a>
                </div>
                <div className="MoASSP-footer-section">
                    <h4 className="MoASSP-footer-heading">Follow Us</h4>
                    <a
                        className="MoASSP-footer-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/Gruvy_edu"
                    >
                        Twitter
                    </a>
                    <a
                        className="MoASSP-footer-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/william-grube-16200a177/"
                    >
                        LinkedIn
                    </a>
                    <a
                        className="MoASSP-footer-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/profile.php?id=100092009478609"
                    >
                        Facebook
                    </a>
                </div>
            </div>
            <div className="MoASSP-footer-bottom">
                <p className="MoASSP-footer-copyright">
                    &copy; {new Date().getFullYear()} Gruvy Education | All rights reserved.
                </p>
            </div>
        </footer>
    );
}

export default BerthaFooter;
