import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './AASAFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavMoASSPOct28 from './NavAASA';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import Modification from '../../images/Modification.png'
import Sandbox from '../../images/Sandbox.png'
import Slide from '../../images/Slide.png'
import CustomGPT from '../../images/CustomGPT.png'
import Peninsula from '../../images/Peninsula.png'
import Path from '../../images/Path.png'
import BSD from '../../images/BSD.jpg'

const AASAPolicyAndGovernace = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top AASA-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavMoASSPOct28 module="teaching" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header AASA-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Policy and Governance Resources</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <section name='ai-policy-practice' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title AASA-course-sub-title aiSupperSummitColor">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                {/* Resource 1 */}
                                <a
                                    href="https://docs.google.com/presentation/d/1chFkzyFTTbDGAVeWw0y4hiuEP-ZBAQvs/edit?usp=sharing&ouid=116264913639920976203&rtpof=true&sd=true"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Slide}
                                            alt="From Policy to Practice: Building a District-Wide AI Framework That Works"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        From Policy to Practice: Building a District-Wide AI Framework That Works
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        All slides with embedded links from our presentation.
                                    </p>
                                </a>

                                {/* Resource 2 */}
                                <a
                                    href="https://chatgpt.com/g/g-678a9a61b63c8191b5dcbc469a1f37d6-k-12-ai-guidance-assistant"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={CustomGPT}
                                            alt="AI Guidance Custom GPT"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Guidance Custom GPT
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        AI Assistant pre-loaded with some of the best knowledge from organizations like CoSN, ISTE, Unesco, US DoE, Digital Promise and TeachAI around developing AI guidance for school districts.
                                    </p>
                                </a>

                                {/* Resource 3 */}
                                <a
                                    href="https://psd401.ai"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Peninsula}
                                            alt="Peninsula School District AI Resources Website"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Peninsula School District AI Resources Website
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Our new website completely dedicated to sharing the work we are doing with AI throughout our district. Find Guidance, Blog Posts/Stories, Presentations from internal and external sharing, tools available to our staff, curated articles/research to read about AI in education, and a catalog of use cases from our staff.
                                    </p>
                                </a>

                                {/* Resource 4 */}
                                <a
                                    href="https://bsd48j-my.sharepoint.com/:p:/r/personal/langfordst_beaverton_k12_or_us/_layouts/15/doc2.aspx?sourcedoc=%7B02A16DFB-83C9-464C-ABBB-55039150C56B%7D&file=Leading%20AI%20to%20scale.pptx&action=edit&mobileredirect=true&ct=1737499026221&wdOrigin=OFFICECOM-WEB.START.UPLOAD&cid=582cf77c-5105-499b-a92c-df144a8a4414&wdPreviousSessionSrc=HarmonyWeb&wdPreviousSession=4b720235-8f50-4243-ab0f-21d6ae38c7b6"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img
                                            src={Path}
                                            alt="Leading AI to Scale: Governance, Privacy, and Change Management"
                                        />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Leading AI to Scale: Governance, Privacy, and Change Management
                                    </h3>
                                    <p className="MoASSP-tool-text">
                                        Use this resource as a path to scale AI in your school district.
                                    </p>
                                </a>
                            </div>
                        </section>
                        <section id="aiSuperSUmmitPartners">
                            <div className="aiSuperSUmmitWrapper">
                                <h1 className="heading">
                                    Resources provided by:
                                </h1>
                                <div className="aisupersummitcards">
                                    {[
                                        BSD,
                                        Peninsula
                                    ].map((item, index) => (
                                        <img key={index} src={item} alt="AI Super Summit Partners" />
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default AASAPolicyAndGovernace;
