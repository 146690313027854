import React from "react";
import Logo from "../../images/logo-no-background.png";
import SecondLogo from "../../images/AASA Logos Black and White/AASA_logo_BLACK.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollLink } from "react-scroll";

const NavAASA = ({ module }) => {
  const navigate = useNavigate();

  const openNavOptions = () => {
    document.getElementById("ASSA-bars-btn").style.display = "none";
    document.getElementById("ASSA-nav-popup").style.display = "flex";
    document.getElementById("ASSA-main-logo").style.display = "none";
    document.getElementById("ASSA-second-logo").style.display = "none";
    document.getElementById("ASSA-apply-button").style.display = "block";

    // Disable scrolling
    document.body.classList.add("no-scroll");
  };

  const closeNavOptions = () => {
    document.getElementById("ASSA-bars-btn").style.display = "flex";
    document.getElementById("ASSA-nav-popup").style.display = "none";
    document.getElementById("ASSA-main-logo").style.display = "flex";
    document.getElementById("ASSA-second-logo").style.display = "flex";
    document.getElementById("ASSA-apply-button").style.display = "block";

    // Enable scrolling
    document.body.classList.remove("no-scroll");
  };

  return (
    <nav className="ASSA-navbar">
      <div className="ASSA-container">
        {/* Left Logo */}
        <div className="ASSA-brand">
          <a href="https://www.aasa.org/" target="_blank" className="ASSA-navbar-brand">
            <img
              src={SecondLogo}
              id="ASSA-main-logo"
              className="ASSA-logo"
              alt="Gruvy Education Logo"
            />
          </a>
        </div>

        {/* Navigation Links */}
        <div className="ASSA-nav-center">
          {/* AASA Home Link */}
          <div className="ASSA-nav-item">
            <Link to="/aasa" className="ASSA-nav-link">
              AASA Home
            </Link>
          </div>

          {/* Resources Dropdown */}
          <div className="ASSA-nav-item ASSA-dropdown">
            <button className="ASSA-dropbtn">
              Resources <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className="ASSA-dropdown-content">
              <Link
                to="/AASATeachingAndLearning"
                className="ASSA-dropdown-link"
              >
                Teaching & Learning
              </Link>
              <Link to="/AASAHumanResources" className="ASSA-dropdown-link">
                Human Resources
              </Link>
              <Link
                to="/AASABusinessAndOperations"
                className="ASSA-dropdown-link"
              >
                Business and Operations
              </Link>
              <Link
                to="/AASACommunicationsMarketingAndSafety"
                className="ASSA-dropdown-link"
              >
                Communications, Marketing & Safety
              </Link>
              <Link to="/AASAHackathonRoom" className="ASSA-dropdown-link">
                Hackathon Room
              </Link>
              <Link to="/AASAPolicyAndGovernance" className="ASSA-dropdown-link">
                Policy & Governance
              </Link>
              <Link to="/AASAAISparkSessions" className="ASSA-dropdown-link">
                AI Spark Sessions
              </Link>
              <Link to="/AASASharkShowcaseSession" className="ASSA-dropdown-link">
                Shark Showcase Session
              </Link>
              {/* <Link to="/AASAPartnerPresentations" className="ASSA-dropdown-link">
                Partner Presentations
              </Link> */}
              <Link to="/AASAAllResources" className="ASSA-dropdown-link">
                All Resources
              </Link>
            </div>
          </div>
        </div>

        {/* Right Side - Second Logo or Apply Button */}
        <div className="ASSA-brand ASSA-brand-right">
          <a
            href="https://www.aasa.org/"
            target="_blank"
            className="ASSA-navbar-brand"
          >
            <img
              src={SecondLogo}
              id="ASSA-second-logo"
              className="ASSA-logo"
              alt="Second Logo"
            />
          </a>
        </div>

        {/* Mobile Menu Icon */}
        <button
          id="ASSA-bars-btn"
          className="ASSA-bars-btn"
          onClick={openNavOptions}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      {/* Mobile Navigation Popup */}
      <div className="ASSA-nav-popup" id="ASSA-nav-popup">
        <div className="ASSA-mobile-nav-header">
          <button onClick={closeNavOptions} className="ASSA-close-btn">
            &times;
          </button>
        </div>
        <div className="ASSA-mobile-nav-links">
          <Link
            to="/aasa"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            AASA Home
          </Link>
          <Link
            to="/AASATeachingAndLearning"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Teaching & Learning
          </Link>
          <Link
            to="/AASAHumanResources"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Human Resources
          </Link>
          <Link
            to="/AASABusinessAndOperations"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Business and Operations
          </Link>
          <Link
            to="/AASACommunicationsMarketingAndSafety"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Communications, Marketing & Safety
          </Link>
          <Link
            to="/AASAHackathonRoom"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Hackathon Room
          </Link>
          <Link
            to="/AASAPolicyAndGovernance"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Policy & Governance
          </Link>
          <Link
            to="/AASAAISparkSessions"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            AI Spark Sessions
          </Link>
          <Link
            to="/AASASharkShowcaseSession"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Shark Showcase Session
          </Link>
          {/* <Link
            to="/AASAPartnerPresentations"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            Partner Presentations
          </Link> */}
          <Link
            to="/AASAAllResources"
            className="ASSA-mobile-nav-link"
            onClick={closeNavOptions}
          >
            All Resources
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavAASA;
